import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMainContext } from "../../../Context";
import { p2e } from "../../../Utils/functions";
import ArrayComponent from "../Components/ArrayComponent";
import CheckEditor from "../Components/CheckEditor";
import CropperGoddess from "../Components/Cropper";
import CustomerAddress from "../Components/CustomerAddress";
import DatePicker from "../Components/DatePicker";
import ExcelUploader from "../Components/ExcelUploader";
import FileUploader from "../Components/FileUploader";
import ImageUploader from "../Components/ImageUploader";
import JsonFormatter from "../Components/JsonFormatter";
import Keywords from "../Components/Keywords";
import MultiImageUploader from "../Components/MultiImagesUploader";
import MultiSelect from "../Components/MultiSelect";
import Select from "../Components/Select";
import SelectImage from "../Components/SelectImage";
import { API } from "../../../Server";
import ShowImages from "../Components/ShowImages";

export const useForm = ({
  route = "",
  elements = [],
  needs = [],
  sort = (state) => state,
  edit = false,
  show = {},
  sortInint = (state) => state,
  initial = {},
  modalInit = {},
  isModal = false,
  customValidate = false,
}) => {
  const { id = false } = useParams();
  const location = useLocation();
  const [form, setForm] = useState({ seo: {} });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(true);
  const [loadingNeed, setLoadingNeed] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const navigate = useNavigate();
  const {
    post,
    state: { user },
    get,
    del,
    put,
  } = useMainContext();

  useEffect(() => {
    if (!isModal) return;
    setForm({ ...form, ...modalInit });
  }, [isModal]);
  useEffect(() => {
    if (!edit) {
      setForm({ seo: {} });
    }
  }, [location.pathname]);
  useEffect(() => {
    if (needs.length > 0) {
      getNesdata();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (edit) {
      editData();
    }
  }, [location.pathname]);

  const submit = async () => {
    if (check()["status"] || edit) {
      if (edit) {
        if (customValidate) {
          if (customValidate(form)) {
            setLoading(true);
            await put(
              `${route}/${form._id || sort(form)._id}`,
              sort(form),
              true,
              true
            );
            setLoading(false);
          } else {
            return;
          }
        } else {
          setLoading(true);
          await put(
            `${route}/${form._id || sort(form)._id}`,
            sort(form),
            true,
            true
          );
          setLoading(false);
        }
      } else {
        setLoading(true);
        await post(route, sort(form), true, true);
        setLoading(false);
      }
    } else {
      renderError();
    }
  };
  const getNesdata = async () => {
    setLoadingNeed(true);
    const result = await post("/main", { keys: needs });
    setData(result.data);
    setLoadingNeed(false);
  };
  const deleteData = async () => {
    if (show.route) {
      setLoadingDelete(true);
      const result = await del(`${show.route}${id ? "/" + id : ""}`);
      navigate(-1);
      setLoadingDelete(false);
    }
  };
  const editData = async () => {
    if (show.route) {
      setLoadingEdit(true);
      const result = await get(
        `${show.route}${id ? "/" + id : ""}${renderQuery(initial)}`,
        {
          [show.key]: id,
          ...initial,
        }
      );
      setForm(sortInint(result.data.data));
      setLoadingEdit(false);
    }
  };

  const renderQuery = (init) => {
    if (init && Object.keys(init).length > 0) {
      return "?" + new URLSearchParams(init).toString();
    }
    return "";
  };

  const handleChange = (key, value, nested = false) => {
    if (nested) {
      if (form[nested]) {
        setForm({ ...form, [nested]: { ...form[nested], [key]: p2e(value) } });
      } else {
        setForm({
          ...form,
          [nested]: {
            [key]: p2e(value),
          },
        });
      }
    } else {
      setForm({ ...form, [key]: p2e(value) });
    }
  };
  const handleValue = (key, nested = false) => {
    if (nested) {
      return (form[nested] && form[nested][key]) || "";
    } else {
      return form[key] || "";
    }
  };
  const check = () => {
    let result = true;
    let emptys = [];
    elements &&
      elements.map((element) => {
        if (Array.isArray(element.key)) {
          element.key.map((key) => {
            if (key in form) {
              if (!form[key]) {
                result = false;
                emptys.push(element.label);
              }
            } else {
              result = false;
              emptys.push(element.label);
            }
          });
        } else {
          if (element.nested && !element.noNeed && element.input !== "file") {
            if (form[element.nested]) {
              if (!form[element.nested][element.key]) {
                result = false;
                emptys.push(element.label);
              }
            } else {
              result = false;
              emptys.push(element.label);
            }
          } else {
            if (!element.noNeed && element.input !== "file") {
              if (element.exist) {
                if (form[element.exist.key] == element.exist.value) {
                  if (element.key in form) {
                    if (!form[element.key]) {
                      result = false;
                      emptys.push(element.label);
                    }
                  } else {
                    result = false;
                    emptys.push(element.label);
                  }
                }
              } else {
                if (element.key in form) {
                  if (!form[element.key]) {
                    result = false;
                    emptys.push(element.label);
                  }
                } else {
                  result = false;
                  emptys.push(element.label);
                }
              }
            }
          }
        }
      });
    return {
      status: result,
      message: emptys,
    };
  };
  const renderError = () => {
    toast.error(`${check()["message"].map((filed) => filed)} required!`);
  };
  const dividData = (key) => {
    return (data && data[key]) || [];
  };

  const renderElement = (element) => {
    switch (element.type) {
      case "avatar":
        return (
          <div className={element.col || "col-12"}>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={API + form[element.key]}
                alt=""
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        );
      case "showImages":
        return (
          <>
            <ShowImages
              element={element}
              handleChange={handleChange}
              handleValue={handleValue}
              form={form}
              setForm={setForm}
              single={element.single}
            />
          </>
        );
      case "images":
        return (
          <div className={element.col || "col-12"}>
            <ImageUploader
              element={element}
              set={(e) => {
                setForm({ ...form, [element.key]: e, previewApi: false });
              }}
              form={form}
              setForm={setForm}
              edit={edit}
            />
          </div>
        );
      case "link":
        if (form[element.key]) {
          return (
            <div className="col-12 mt-2">
              <div className="alert alert-success">
                <Link
                  to={`/user/update/${form[element.key]._id}`}
                  target="_blank"
                >
                  {element.label}
                </Link>
              </div>
            </div>
          );
        }
        return "";
      case "item_link":
        if (form[element.key]) {
          return (
            <div className="col-12 mt-2">
              <div className="alert alert-success">
                {form[element.key].expireAt === undefined ? (
                  <Link
                    to={`/request/update/${form[element.key]._id}`}
                    target="_blank"
                  >
                    {element.label}
                  </Link>
                ) : (
                  <Link
                    to={`/offer/update/${form[element.key]._id}`}
                    target="_blank"
                  >
                    {element.label}
                  </Link>
                )}
              </div>
            </div>
          );
        }
        return "";

      case "chat_link":
        if (form[element.key]) {
          return (
            <div className="col-12 mt-2">
              <div className="alert alert-success">
                <Link
                  to={`/conversations/chat/${form[element.key].conversationID}`}
                  target="_blank"
                >
                  {element.label}
                </Link>
              </div>
            </div>
          );
        }
        return "";

      case "multiImages":
        return (
          <>
            <div className={element.col || "col-12"}>
              <MultiImageUploader
                element={element}
                set={(e) => {
                  setForm({ ...form, [element.key]: e, previewApi: false });
                }}
                form={form}
                setForm={setForm}
                edit={edit}
              />
            </div>
          </>
        );
      case "select":
        if (element.exist) {
          if (form[element.exist.key] == element.exist.value) {
            return (
              <div className={`form-group my-2 ${element.col || "col-12"} `}>
                <Select
                  element={element}
                  handleChange={handleChange}
                  handleValue={handleValue}
                  loading={loadingNeed}
                  data={dividData(element.need) || []}
                />
              </div>
            );
          } else {
            return <></>;
          }
        }
        if (element.access) {
          if (element.access.includes(user.user.role)) {
            return (
              <div className={`form-group my-2 ${element.col || "col-12"} `}>
                <Select
                  element={element}
                  handleChange={handleChange}
                  handleValue={handleValue}
                  loading={loadingNeed}
                  data={dividData(element.need) || []}
                />
              </div>
            );
          } else {
            return <></>;
          }
        } else {
          return (
            <div className={element.col || "col-12"}>
              <Select
                element={element}
                handleChange={handleChange}
                handleValue={handleValue}
                loading={loadingNeed}
                data={dividData(element.need) || []}
              />
            </div>
          );
        }

      case "textarea":
        return (
          <div className={element.col || "col-12"}>
            <div className="form-group">
              <label htmlFor="exampleTextarea1">{element.label}</label>
              <textarea
                onChange={({ target: { value } }) => {
                  handleChange(element.key, value, element.nested);
                }}
                value={handleValue(element.key, element.nested)}
                className="form-control"
                id="exampleTextarea1"
                placeholder={element.label}
                rows={4}
                style={{ minHeight: "10rem" }}
              />
            </div>
          </div>
        );
      case "keywords":
        return <Keywords element={element} form={form} setForm={setForm} />;
      case "attachments":
        return (
          <div className={element.col || "col-12"}>
            <div className="form-group mt-2">
              <label htmlFor="exampleTextarea1">{element.label}</label>
              {form[element.key]?.length > 0 ? (
                <>
                  {form[element.key].map((item, key) => {
                    return (
                      <>
                        {console.log(item)}
                        <div className=" g-0 align-items-center py-2 position-relative border-bottom border-200  ">
                          <label style={{ marginRight: "5px" }}>
                            {key + 1} - {"   "}{" "}
                          </label>
                          <a
                            href={"http://kallii.com/" + item}
                            download={true}
                            target="_blank"
                          >
                            {"http://kallii.com/" + item}
                          </a>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                    <div className="col ps-card py-1 position-static">
                      <div className="d-flex align-items-center">
                        <div className="flex-1">
                          <h6 className="mb-0 d-flex align-items-center">
                            Empty
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case "editor":
        return (
          <CheckEditor
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
          />
        );
      case "multiselect":
        return (
          <div className={element.col || "col-12"}>
            <MultiSelect
              loading={loadingNeed}
              data={dividData(element.need) || []}
              handleChange={handleChange}
              element={element}
              handleValue={handleValue}
            />
          </div>
        );
      case "toggle":
        if (element.exist) {
          if (form[element.exist.key] == element.exist.value) {
            return (
              <div className={element.col || "col-12"}>
                <div class="form-check form-switch">
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    {element.label}
                  </label>
                  <input
                    class="form-check-input"
                    id="flexSwitchCheckDefault"
                    type="checkbox"
                    checked={handleValue(element.key) == "true"}
                    onChange={() => {
                      handleChange(
                        element.key,
                        handleValue(element.key) == "true" ? "false" : "true"
                      );
                    }}
                  />
                </div>
              </div>
            );
          } else {
            return <></>;
          }
        }
        return (
          <div className={element.col || "col-12 mt-2"}>
            <div class="form-check form-switch">
              <label class="form-check-label" for="flexSwitchCheckDefault">
                {element.label}
              </label>
              <input
                class="form-check-input"
                id="flexSwitchCheckDefault"
                type="checkbox"
                checked={handleValue(element.key) == "true"}
                onChange={() => {
                  handleChange(
                    element.key,
                    handleValue(element.key) == "true" ? "false" : "true"
                  );
                }}
              />
            </div>
          </div>
        );
      case "date":
        return (
          <DatePicker
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
            form={form}
            setForm={setForm}
            single={element.single}
          />
        );
      case "file":
        return (
          <FileUploader
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
            form={form}
            edit={edit}
            setForm={setForm}
          />
        );
      case "excel":
        return (
          <ExcelUploader
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
            form={form}
            edit={edit}
            setForm={setForm}
          />
        );
      case "customerAddress":
        return (
          <>
            <CustomerAddress
              set={handleChange}
              data={edit && form}
              value={handleValue}
              setForm={setForm}
              element={element}
              form={form}
            />
          </>
        );
      case "selectImage":
        return (
          <>
            <SelectImage
              element={element}
              handleChange={handleChange}
              handleValue={handleValue}
              form={form}
              edit={edit}
              setForm={setForm}
            />
          </>
        );
      case "cropper":
        return (
          <CropperGoddess
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
            form={form}
            edit={edit}
            setForm={setForm}
          />
        );
      case "json":
        return (
          <JsonFormatter
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
            form={form}
            edit={edit}
            setForm={setForm}
          />
        );
      case "array":
        return <ArrayComponent form={form} setForm={setForm} />;

      case "list":
        return (
          <>
            <div className="col-lg-12 mt-2 pe-lg-2 mb-3">
              <div className="card h-lg-100 overflow-hidden">
                <div className="card-header bg-light">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="mb-0">{element.title}</h6>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  {form[element.key].length > 0 ? (
                    <>
                      {form[element.key].map((item) => {
                        return (
                          <>
                            <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                              <div className="col ps-card py-1 position-static">
                                <div className="d-flex align-items-center">
                                  <div className="avatar avatar-xl me-3">
                                    <div className="avatar-name rounded-circle bg-soft-primary text-dark">
                                      <span className="fs-0 text-primary">
                                        id
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex-1">
                                    <h6 className="mb-0 d-flex align-items-center">
                                      <Link
                                        to={`/user/update/${item}`}
                                        className="text-800 stretched-link"
                                        href="#!"
                                      >
                                        {item}
                                      </Link>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                        <div className="col ps-card py-1 position-static">
                          <div className="d-flex align-items-center">
                            <div className="flex-1">
                              <h6 className="mb-0 d-flex align-items-center">
                                Empty
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      default:
        if (element.exist) {
          if (form[element.exist.key] == element.exist.value) {
            return (
              <div className={`form-group my-2 ${element.col || "col-12"} `}>
                <label htmlFor="exampleInputName1">{element.label}</label>
                <input
                  onChange={({ target: { value } }) => {
                    handleChange(element.key, value, element.nested);
                  }}
                  value={handleValue(element.key, element.nested)}
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  placeholder={element.label}
                />
              </div>
            );
          } else {
            return <></>;
          }
        } else {
          if (element.notExist) {
            if (form[element.notExist.key] != element.notExist.value) {
              return (
                <div className={`form-group my-2 ${element.col || "col-12"} `}>
                  <label htmlFor="exampleInputName1">{element.label}</label>
                  <input
                    onChange={({ target: { value } }) => {
                      handleChange(element.key, value, element.nested);
                    }}
                    value={handleValue(element.key, element.nested)}
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder={element.label}
                  />
                  {element.help && <span>{element.help}</span>}
                </div>
              );
            } else {
              return <></>;
            }
          } else {
            return (
              <div className={`form-group my-2 ${element.col || "col-12"} `}>
                <label htmlFor="exampleInputName1">{element.label}</label>
                <input
                  onChange={({ target: { value } }) => {
                    handleChange(element.key, value, element.nested);
                  }}
                  value={handleValue(element.key, element.nested)}
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  placeholder={element.label}
                />
                {element.help && <span>{element.help}</span>}
              </div>
            );
          }
        }
    }
  };
  return {
    setForm,
    submit,
    loading,
    form,
    setForm,
    handleChange,
    handleValue,
    check,
    getNesdata,
    data,
    loadingNeed,
    renderElement,
    loadingEdit,
    deleteData,
    loadingDelete,
    setLoadingDelete,
  };
};
